import moment from "moment";

export interface IPallet {
	palletId: string;
	loadedOn: string;
	manuallyEntered: boolean;
	palletType: string;
	isDamaged: boolean;
	isUpstacked: boolean;
	locationInTrailer: string;
	orderInTrailer?: number;
	commodity: string;
	commodityManuallyEntered: boolean;
	store: IPalletStore;
	loadedBy: IPalletLoadedBy;
	cartons?: number;
	weight?: number;
	damagePhotos?: string[];
	isUpstackedWith?: IPallet[];
	wasPalletTimberKept?: boolean;

	// NonDB - UI Only
	isDeleted?: boolean;
}

export interface IPallet_Unload {
	palletId: string;
	scannedAt: Date;
	scannedBy: IPalletLoadedBy;
}

export class PalletsListFilter {
	startTimeLocal = moment().add(-1, 'day').format('YYYY-MM-DD 00:00');
	finishTimeLocal = moment().format('YYYY-MM-DD 23:59');
	status: '' | 'INPROGRESS' | 'FINISHED' | 'CANCELLED' = '';
	fleetNumber?: string;
	depotId?: string;
	workerId?: string;
	runNumber?: string;
}


//#region LOADS
export interface IPalletLoadListItem {
	id: string;
	fleetNumber: string;
	workerId: string;
	workerName: string;
	depotId: number;
	depotName: string;
	runNumber: string;
	loadStartedOn: string;
	deviceName: string;
	totalPallets: number;
	loadFinishedOn: string | null;
	loadCancelledOn: string | null;
	status: "INPROGRESS" | "FINISHED";
	isPaused: boolean;
	trailerPalletCapacity: number;
	dockNumber?: number;

	loaders: { id: string, name: string }[];
}

export interface IPalletLoadDetails {
	id?: string;

	// Depot Data
  depotId: string;
  depotName: string;

	// Worker Data
  workerId?: string;
  workerName?: string;

  // Trailer Data
  fleetNumber?: string;
  isTrailerClean?: boolean;
  trailerPalletCapacity?: number;
  fleetNumberManuallyEntered?: boolean;
  trailerPhotos?: string[];

  // Trailer Zone 1
  trailerZone1SetTemp?: number;
  trailerZone1ActualTemp?: number;

  // Trailer Zone 2
  trailerZone2SetTemp?: number;
  trailerZone2ActualTemp?: number;

  // Trailer Zone 3
  trailerZone3SetTemp?: number;
  trailerZone3ActualTemp?: number;

  // Stock Temperature History
  freezerStockTemp?: IStockTemp;
  chillerStockTemp?: IStockTemp;

  loadStartedOn: string;
  loadFinishedOn?: string;
  loadCancelledOn?: string;
	loadDeletedOn?: string;
  cancelReason?: string;
  deviceName: string;
  
  pallets: IPallet[];
  run: IPalletRun;
	dockNumber?: number;
}

export class PalletLoadDetailsForm implements Omit<IPalletLoadDetails, 'trailerPalletCapacity' | 'depotId' | 'dockNumber'> {
	id?: string;

	// Depot Data
  depotId: string;
  depotName: string;

	// Worker Data
  workerId: string;
  workerName: string;

  // Trailer Data
  fleetNumber: string;
  isTrailerClean?: boolean;
  trailerPalletCapacity: string;
  fleetNumberManuallyEntered: boolean;
  trailerPhotos?: string[];

  // Trailer Zone 1
  trailerZone1SetTemp?: number;
  trailerZone1ActualTemp?: number;

  // Trailer Zone 2
  trailerZone2SetTemp?: number;
  trailerZone2ActualTemp?: number;

  // Trailer Zone 3
  trailerZone3SetTemp?: number;
  trailerZone3ActualTemp?: number;

  // Stock Temperature History
  freezerStockTemp?: IStockTemp;
  chillerStockTemp?: IStockTemp;

  loadStartedOn: string;
  loadFinishedOn?: string;
  loadCancelledOn?: string;
	loadDeletedOn?: string;
  cancelReason?: string;
  deviceName: string;
  
  pallets: IPallet[];
  run: IPalletRun;
	dockNumber: string;

	constructor(details?: IPalletLoadDetails) {
		this.id = details?.id;

		// Depot Data
		this.depotId = details?.depotId || '';
		this.depotName = details?.depotName || '';

		// Worker Data
		this.workerId = details?.workerId || '';
		this.workerName = details?.workerName || '';

		// Trailer Data
		this.fleetNumber = details?.fleetNumber || '';
		this.isTrailerClean = details?.isTrailerClean;
		this.trailerPalletCapacity = details?.trailerPalletCapacity?.toString() || '';
		this.fleetNumberManuallyEntered = details?.fleetNumberManuallyEntered || false;
		this.trailerPhotos = details?.trailerPhotos;

		// Trailer Zone 1
		this.trailerZone1SetTemp = details?.trailerZone1SetTemp;
		this.trailerZone1ActualTemp = details?.trailerZone1ActualTemp;

		// Trailer Zone 2
		this.trailerZone2SetTemp = details?.trailerZone2SetTemp;
		this.trailerZone2ActualTemp = details?.trailerZone2ActualTemp;

		// Trailer Zone 3
		this.trailerZone3SetTemp = details?.trailerZone3SetTemp;
		this.trailerZone3ActualTemp = details?.trailerZone3ActualTemp;

		// Stock Temperature History
		this.freezerStockTemp = details?.freezerStockTemp;
		this.chillerStockTemp = details?.chillerStockTemp;

		this.loadStartedOn = details?.loadStartedOn ? moment(details.loadStartedOn).toISOString() : '';
		this.loadFinishedOn = details?.loadFinishedOn ? moment(details.loadFinishedOn).toISOString() : '';
		this.loadCancelledOn = details?.loadCancelledOn ? moment(details.loadCancelledOn).toISOString() : '';
		this.loadDeletedOn = details?.loadDeletedOn ? moment(details.loadDeletedOn).toISOString() : '';
		this.cancelReason = details?.cancelReason;
		this.deviceName = details?.deviceName || '';
		
		this.pallets = details?.pallets || [];
		this.run = details?.run || { id: '', name: '' };
		this.dockNumber = details?.dockNumber?.toString() || '';
	}
}

export class PalletDetailsForm {
	palletId: string;
	loadedOn: string;
	manuallyEntered: boolean;
	palletType: string;
	isDamaged: boolean;
	isUpstacked: boolean;
	locationInTrailer: string;
	orderInTrailer?: number;
	commodity: string;
	commodityManuallyEntered: boolean;
	cartons?: number;
	weight?: number;
	store: IPalletStore;
	damagePhotos?: string[];
	isUpstackedWith?: IPallet[];
	wasPalletTimberKept?: boolean
	loadedBy: IPalletLoadedBy;

	constructor(pallet: IPallet) {
		this.palletId = pallet.palletId;
		this.loadedOn = pallet.loadedOn;
		this.manuallyEntered = pallet.manuallyEntered;
		this.palletType = pallet.palletType;
		this.isDamaged = pallet.isDamaged;
		this.isUpstacked = pallet.isUpstacked;
		this.locationInTrailer = pallet.locationInTrailer;
		this.orderInTrailer = pallet.orderInTrailer;
		this.commodity = pallet.commodity;
		this.commodityManuallyEntered = pallet.commodityManuallyEntered;
		this.cartons = pallet.cartons;
		this.weight = pallet.weight;
		this.store = pallet.store;
		this.damagePhotos = pallet.damagePhotos;
		this.isUpstackedWith = pallet.isUpstackedWith;
		this.wasPalletTimberKept = pallet.wasPalletTimberKept;
		this.loadedBy = pallet.loadedBy;
	}
}

// Interface to copy the LoadedBy object in a Pallet for adding a new Pallet
export interface IPalletLoadedBy {
	id: string;
	name: string;
}

export class PalletLoad_PUT {
		id: string;

    // Depot Data
    depotId: number;
    depotName: string;

    // Worker Data
    workerId: string;
    workerName: string;

    // Trailer Data
    fleetNumber: string;
    isTrailerClean?: boolean;
    trailerPalletCapacity: number;
    fleetNumberManuallyEntered?: boolean;
    trailerPhotos?: string[];

    // Trailer Zone 1
    trailerZone1SetTemp?: number;
    trailerZone1ActualTemp?: number;

    // Trailer Zone 2
    trailerZone2SetTemp?: number;
    trailerZone2ActualTemp?: number;

    // Trailer Zone 3
    trailerZone3SetTemp?: number;
    trailerZone3ActualTemp?: number;

    // Stock Temperature
    freezerStockTemp?: IStockTemp;
    chillerStockTemp?: IStockTemp;

    loadStartedOn: string;
    loadFinishedOn?: string;
    loadDeletedOn?: string;
    loadCancelledOn?: string;
    cancelReason?: string;

    deviceName: string;
    pallets: PalletPUT[];
    run: IPalletRun;
		dockNumber: number;

  constructor (load: PalletLoadDetailsForm) {
    this.id = load.id!;
    this.depotId = parseInt(load.depotId);
    this.depotName = load.depotName;
    this.workerId = load.workerId;
    this.workerName = load.workerName;
    this.loadStartedOn = moment(load.loadStartedOn).utc().toISOString();
    this.loadFinishedOn = load.loadFinishedOn ? moment(load.loadFinishedOn).utc().toISOString() : undefined;

    // Trailer Data
    this.fleetNumber = load.fleetNumber; 
    this.isTrailerClean = load.isTrailerClean;
		this.trailerPalletCapacity = parseInt(load.trailerPalletCapacity);

    // Stock Temperature History (only if temp has value)
    this.freezerStockTemp = load.freezerStockTemp || undefined;
    this.chillerStockTemp = load.chillerStockTemp || undefined;

    // Trailer Zone 1
    this.trailerZone1SetTemp = load.trailerZone1SetTemp || undefined
    this.trailerZone1ActualTemp = load.trailerZone1ActualTemp || undefined;

    // Trailer Zone 2
    this.trailerZone2SetTemp = load.trailerZone2SetTemp || undefined;
    this.trailerZone2ActualTemp = load.trailerZone2ActualTemp || undefined;

    // Trailer Zone 3
    this.trailerZone3SetTemp = load.trailerZone3SetTemp || undefined;
    this.trailerZone3ActualTemp = load.trailerZone3ActualTemp || undefined;

    this.trailerPhotos = load.trailerPhotos;
    this.fleetNumberManuallyEntered = load.fleetNumberManuallyEntered;
    this.cancelReason = load.cancelReason || undefined;
    this.loadCancelledOn = load.loadCancelledOn || undefined;

		this.deviceName = load.deviceName;
    this.pallets = load.pallets.filter(pallet => !pallet.isDeleted).map(pallet => new PalletPUT(pallet));
		this.run = load.run;
		this.dockNumber = parseInt(load.dockNumber);
	}
}	

class PalletPUT {
	palletId: string;
	loadedOn: string;
	manuallyEntered: boolean;
	palletType: string;
	isDamaged?: boolean;
	isUpstacked?: boolean;
	locationInTrailer: string;
	orderInTrailer?: number;
	commodity: string;
	commodityManuallyEntered: boolean;
	cartons?: number;
	weight?: number;
	store: IPalletStore;
	damagePhotos?: string[];
	isUpstackedWith?: PalletPUT[];
	wasPalletTimberKept: boolean;
	loadedBy: IPalletLoadedBy;

	constructor(pallet: PalletDetailsForm) {
		this.palletId = pallet.palletId;
		this.palletType = pallet.palletType;
		this.locationInTrailer = pallet.locationInTrailer;
		this.orderInTrailer = pallet.orderInTrailer;
		this.commodity = pallet.commodity;
		this.store = pallet.store;
		this.loadedOn = pallet.loadedOn;
		this.manuallyEntered = pallet.manuallyEntered || false;
		this.isDamaged = pallet.isDamaged || false;
		this.isUpstacked = pallet.isUpstacked || false;
		this.commodityManuallyEntered = pallet.commodityManuallyEntered || false;
		this.cartons = pallet.cartons || undefined;
		this.weight = pallet.weight || undefined;
		this.damagePhotos = pallet.damagePhotos || undefined;
		this.isUpstackedWith = pallet.isUpstackedWith?.filter(pallet => !pallet.isDeleted).map(pallet => new PalletPUT(pallet));
		this.wasPalletTimberKept = pallet.wasPalletTimberKept || false;
		this.loadedBy = pallet.loadedBy;
	}
}

export interface IPalletRun {
	id: string;
	name: string;
}

export interface IPalletStore {
	id: number;
	name: string;
	storeNumber: string;
}

export interface IStockTemp {
	temp: number;
	collectedAt: Date;
}
// #endregion

// #region UNLOADS
export interface IUnloadedBy {
  id: string;
  name: string;
  unloadPercentage: number;
}

export interface IUnloadDepot {
  id: number;
  name: string;
}

export interface IPalletUnloadListItem {
	id: string;
  depot: IUnloadDepot;
  run?: IPalletRun | null;
  unloadedBy: IUnloadedBy[];
  dockNumber: string;
  deviceName: string;
  fleetNumber: string;
  isTrailerEmpty?: boolean;

	// pallets?: IPallet_Unload[];
	totalScannedPallets?: number;
  totalEmptyPalletsLoscam: number;
	totalPalletsWithCratesLoscam: number;
  totalEmptyPalletsChep: number;
	totalPalletsWithCratesChep: number;
  totalEmptyPalletsT3: number;
  // totalEmptyCrates?: number;

  startedAt: string;
  finishedAt: string | null;
  deletedAt: string | null;
  cancelledAt: string | null;

	isPaused: boolean;
	loaders: { id: string, name: string }[];

	// Non-DB - UI Only
	totalPalletsToUnload?: number;
}

export interface IPalletUnloadDetails {
	id: string;
	depot: IUnloadDepot;
	run?: IPalletRun | null;
	unloadedBy: IUnloadedBy[];
	dockNumber: string;
	deviceName: string;
	fleetNumber: string;
	isTrailerClean?: boolean;
	fleetNumberManuallyEntered?: boolean;
	trailerPhotoIds: string[];

	pallets?: IPallet_Unload[];
	isTrailerEmpty?: boolean;
  totalEmptyPalletsLoscam?: number;
	totalPalletsWithCratesLoscam: number;
  totalEmptyPalletsChep?: number;
	totalPalletsWithCratesChep: number;
  totalEmptyPalletsT3?: number;

	createdAt: string;
	startedAt: string;
	finishedAt?: string | null;
	deletedAt?: string | null;
	cancelledAt?: string | null;
	cancelReason?: string;
}

// #region PalletUnloadDetailsForm
export class PalletUnloadDetailsForm {
	id?: string;
	depot?: PalletUnloadDepotForm;
	run?: IPalletRun | null;
	unloadedBy: UnloadedByForm[];
	dockNumber: string;
	deviceName: string;
	fleetNumber: string;
	isTrailerClean?: boolean;
	fleetNumberManuallyEntered?: boolean;
	trailerPhotoIds: string[];

	pallets?: IPallet_Unload[];
	isTrailerEmpty?: boolean;
  totalEmptyPalletsLoscam: string;
	totalPalletsWithCratesLoscam: string;
  totalEmptyPalletsChep: string;
	totalPalletsWithCratesChep: string;
  totalEmptyPalletsT3: string;

	createdAt: string;
	startedAt: string;
	finishedAt: string;
	deletedAt: string;
	cancelledAt: string;
	cancelReason: string;

	constructor(details?: IPalletUnloadDetails) {
		this.id = details?.id;
		this.depot = new PalletUnloadDepotForm(details?.depot);
		//this.Run = details?.Run;
		this.unloadedBy = details?.unloadedBy.map(unloadedBy => new UnloadedByForm(unloadedBy)) || [new UnloadedByForm()];
		this.dockNumber = details?.dockNumber || '';
		this.deviceName = details?.deviceName || '';
		this.fleetNumber = details?.fleetNumber || '';
		this.isTrailerClean = details?.isTrailerClean;
		this.fleetNumberManuallyEntered = details?.fleetNumberManuallyEntered;
		this.trailerPhotoIds = details?.trailerPhotoIds || [];

		this.pallets = details?.pallets || [];
		this.isTrailerEmpty = details?.isTrailerEmpty;
		this.totalEmptyPalletsLoscam = details?.totalEmptyPalletsLoscam?.toString() || '';
		this.totalPalletsWithCratesLoscam = details?.totalPalletsWithCratesLoscam?.toString() || '';
		this.totalEmptyPalletsChep = details?.totalEmptyPalletsChep?.toString() || '';
		this.totalPalletsWithCratesChep = details?.totalPalletsWithCratesChep?.toString() || '';
		this.totalEmptyPalletsT3 = details?.totalEmptyPalletsT3?.toString() || '';

		this.createdAt = details?.createdAt || '';
		this.startedAt = details?.startedAt || '';
		this.finishedAt = details?.finishedAt || '';
		this.deletedAt = details?.deletedAt || '';
		this.cancelledAt = details?.cancelledAt || '';
		this.cancelReason = details?.cancelReason || '';
	}
}

export class PalletUnloadShuttleDetailForm {
	palletId: string;
	scannedAt: Date;
	scannedBy: IPalletLoadedBy;

	constructor(pallet: IPallet_Unload) {
		this.palletId = pallet.palletId;
		this.scannedAt = pallet.scannedAt;
		this.scannedBy = { id: pallet.scannedBy.id, name: pallet.scannedBy.name };
	}
}

export class PalletUnloadDepotForm {
	id: string;
	name: string;

	constructor(depot?: IUnloadDepot) {
		this.id = depot?.id.toString() || "";
		this.name = depot?.name || "";
	}
}

export class UnloadedByForm {
	id: string;
	name: string;
	unloadPercentage: string;

	constructor(unloadedBy?: IUnloadedBy) {
		this.id = unloadedBy?.id || "";
		this.name = unloadedBy?.name || "";
		this.unloadPercentage = unloadedBy?.unloadPercentage?.toString() || "";
	}
}
// #endregion

// #region PalletUnload_POST_PUT
export class PalletUnload_POST_PUT {
	id?: string;
	depot: UnloadDepot_POST_PUT;
	//run?: null;
	unloadedBy: UnloadBy_POST_PUT[];
	dockNumber: string;
	deviceName: string;
	fleetNumber: string;
	isTrailerClean?: boolean;
	fleetNumberManuallyEntered?: boolean;
	trailerPhotoIds: string[];

	pallets?: IPallet_Unload[];
	isTrailerEmpty?: boolean;
  totalEmptyPalletsLoscam?: number;
	totalPalletsWithCratesLoscam?: number;
  totalEmptyPalletsChep?: number;
	totalPalletsWithCratesChep?: number;
  totalEmptyPalletsT3?: number;

	startedAt: string;
	finishedAt?: string;
	cancelReason?: string;

	constructor (unload: PalletUnloadDetailsForm) {
		this.id = unload.id!;
		this.depot = new UnloadDepot_POST_PUT(unload.depot!);
		//this.run = unload.Run!;
		this.unloadedBy = unload.unloadedBy?.map(unloadedBy => new UnloadBy_POST_PUT(unloadedBy)) || [];
		this.dockNumber = unload.dockNumber;
		this.deviceName = unload.deviceName;
		this.fleetNumber = unload.fleetNumber;
		this.isTrailerClean = unload.isTrailerClean;
		this.fleetNumberManuallyEntered = unload.fleetNumberManuallyEntered || false;
		this.trailerPhotoIds = unload.trailerPhotoIds;

		this.pallets = unload.pallets || [];
		this.isTrailerEmpty = unload.isTrailerEmpty || false;
		this.totalEmptyPalletsLoscam = parseInt(unload.totalEmptyPalletsLoscam);
		this.totalPalletsWithCratesLoscam = parseInt(unload.totalPalletsWithCratesLoscam);
		this.totalEmptyPalletsChep = parseInt(unload.totalEmptyPalletsChep);
		this.totalPalletsWithCratesChep = parseInt(unload.totalPalletsWithCratesChep);
		this.totalEmptyPalletsT3 = parseInt(unload.totalEmptyPalletsT3);

		this.startedAt = moment(unload.startedAt).utc().toISOString();
		this.finishedAt = moment(unload.finishedAt).utc().toISOString();
		this.cancelReason = unload.cancelReason;
	}
}

export class UnloadDepot_POST_PUT {
	id: number;
	name: string;

	constructor(depot: PalletUnloadDepotForm) {
		this.id = parseInt(depot.id);
		this.name = depot.name;
	}
}

export class UnloadBy_POST_PUT {
	id: string;
	name: string;
	unloadPercentage: number;

	constructor(unloadedBy: UnloadedByForm) {
		this.id = unloadedBy.id;
		this.name = unloadedBy.name;
		this.unloadPercentage = parseInt(unloadedBy.unloadPercentage);
	}
}
// #endregion