import React, { useEffect, useState } from 'react';
import PalletApi from '../../../../app/api/PalletApi';
import { IError, IReactSelectReturn } from '../../../../app/models/Application';
import { IPalletLoadDetails, IPalletLoadListItem, IPalletUnloadListItem } from '../../../../app/models/Pallet';
import { PlacesListNew } from '../../../common/lists';
import { Callout, ErrorBox, Loader } from '../../../common/uiElements';
import PalletLoadBox from './PalletLoadBox';
import { Location } from 'history';
import './PalletLoadPanel.css';
import FontAwesome from 'react-fontawesome';
import { orderBy } from 'lodash';
import PalletLoadDetailsModal from '../../pallets/PalletLoad/Details/PalletLoadDetailsModal';
import PalletUnloadBox from './PalletUnloadBox';
import PalletLoadPanelSummary from './PalletLoadPanelSummary';
import { Col, Row } from 'react-bootstrap';

interface IProps {
  location: Location & { query: { [key: string]: string } };
}

const PalletLoad: React.FC<IProps> = props => {
	const query = props.location.query;

	const [depotIdFilter, setDepotIdFilter] = useState(query.depotId || '');
	const [loadsList, setLoadsList] = useState<IPalletLoadListItem[] | undefined>(undefined);
	const [unloadsList, setUnloadsList] = useState<IPalletUnloadListItem[] | undefined>(undefined);
	// Used for the unloads to get total pallets loaded
	const [loadsListWithDetails, setLoadsListWithDetails] = useState<IPalletLoadDetails[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalLoadId, setModalLoadId] = useState('');
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [errorLoading, setErrorLoading] = useState<IError | undefined>(undefined);

	useEffect(() => {
		fetchData(true);
		// fetch every 5 minutes
		const interval = setInterval(fetchData, 1000 * 60 * 5);
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		setTimeout(() => document.getElementsByTagName('body')[0].classList.add('sidebar-collapse'), 400);
	}, [])

	useEffect(() => {
		if (!unloadsList) 
			return;

		unloadsList.forEach(unload => {
			unload.totalPalletsToUnload = loadsListWithDetails.find(load => load.run?.id == unload.run?.id)?.pallets?.length;
		});

		setUnloadsList(unloadsList);
	}, [unloadsList, loadsListWithDetails]);

	const fetchData = async (showLoading?: boolean) => {
		if (showLoading)
			setIsLoading(true);
		else
			setIsRefreshing(true);

		// Fetch Loads
		try {
			const loads = await PalletApi.loads.getLoadsListForPanel();
			const loadsNotCancelled = loads.filter(load => !load.loadCancelledOn);
			setLoadsList(loadsNotCancelled);
		} catch (error) {
			setErrorLoading(error as any);
		} 

		// Fetch Unloads
		let unloadsNotCancelled: IPalletUnloadListItem[] = [];
		try {
			const unloads = await PalletApi.unloads.getUnloadsListForPanel();
			unloadsNotCancelled = unloads.filter(unload => !unload.cancelledAt);
			setUnloadsList(unloadsNotCancelled);
		} catch (error) {
			setErrorLoading(error as any);
		} 

		try {
			// Fetch Load data for unloads that are not already on the list
			const unloadsNotOnList = unloadsNotCancelled.filter(unload => loadsListWithDetails.some(load => load.run?.id == unload.run?.id) === false);
			const loadsToAddToListWithDetails = [];
			
			for (const unload of unloadsNotOnList) {
				if (!unload.run?.id)
					continue;

				const loadData = await PalletApi.loads.getLoadDetailsByRunId(unload.run.id);
				loadsToAddToListWithDetails.push(loadData);
			}

			setLoadsListWithDetails([...loadsListWithDetails, ...loadsToAddToListWithDetails]);
		} catch (error) {
			setErrorLoading(error as any);
		}

		setIsLoading(false);
		setIsRefreshing(false);
	}
	
	const handleChangeDepot = (selectedItem: IReactSelectReturn) => {
		setDepotIdFilter(selectedItem.value?.toString() || '');
	};

	const handleModalChanges = (id: string, show: boolean) => {
		setModalLoadId(id);
		setShowModal(show);
	}

	const filteredUnloadsList = unloadsList ? unloadsList.filter(p => !p.cancelledAt && (!depotIdFilter || p.depot.id.toString() === depotIdFilter)) : undefined;
	const unloadsCompleted = orderBy(filteredUnloadsList?.filter(p => p.finishedAt) || [], ['finishedAt'], ['desc']);
	const unloadsInProgress = filteredUnloadsList?.filter(p => !p.finishedAt) || [];
	
	const filteredLoadsList = loadsList ? loadsList.filter(p => !p.loadCancelledOn && (!depotIdFilter || p.depotId.toString() === depotIdFilter)) : undefined;
	const loadsCompleted = orderBy(filteredLoadsList?.filter(p => p.loadFinishedOn) || [], ['loadFinishedOn'], ['desc']);
	const loadsInProgress = filteredLoadsList?.filter(p => !p.loadFinishedOn && p.fleetNumber && p.workerId) || [];

	return (
		<section className="content panel-live-monitoring">
			<div className="title">
				<span>Pallet Load - Live Monitoring</span>
				<PlacesListNew 
					value={depotIdFilter} 
					type="preStart"
					placeholder="All Depots"
					onChange={handleChangeDepot}
				/>
				{ isRefreshing && <Loader inline small /> }
			</div>
			{
				isLoading || errorLoading ? (
					errorLoading ? (
						<ErrorBox error={errorLoading} retryFunc={fetchData} />
					) : (
						<Loader isLoading={isLoading} />
					)
				) : (
					(!filteredLoadsList || filteredLoadsList.length === 0)
					&& (!filteredUnloadsList || filteredUnloadsList.length === 0) ? (
						<Callout title="NO DATA TO SHOW" color="info" />
					) : (
						<div id="pallet-load-panel">
							<PalletLoadPanelSummary loads={filteredLoadsList || []} unloads={filteredUnloadsList || []} />
							<div className="flex flex-column panel-row-container loads-panel-header">
								<div className="flex-1 flex panel-row">
									<span className="flex-2">RUN</span>
									<span className="flex-2">DEPOT</span>
									<span className="flex-1">DOCK</span>
									<span className="flex-2">TRAILER</span>
									<span className="flex-2">SCANNED</span>
									<span className="flex-3">LOADER</span>
									<span className="flex-2">DURATION</span>
								</div>
							</div>
							<div className="loads-panel-body">
								{ 
									loadsInProgress.length === 0 && unloadsInProgress.length === 0 ? (
										<Callout italicTitle title="NO LOADS OR UNLOADS IN PROGRESS AT THE MOMENT" color="info" />
									) : (
										<>
											{ loadsInProgress.map((load, index) => <PalletLoadBox key={index} data={load} onClick={(id) => handleModalChanges(id, true) }/>)  }
											{ unloadsInProgress.map((unload, index) => <PalletUnloadBox key={index} data={unload} onClick={(id) => handleModalChanges(id, true) }/>) }
										</>
									)
								}
								{
									(loadsCompleted.length > 0 || unloadsCompleted.length > 0) &&  (
										<>
											<FontAwesome name="arrow-down" className="divider" />
											<Row>
												<Col lg={6}>
												<div className="flex flex-column panel-row-container loads-panel-header small">
													<div className="flex-1 flex panel-row">
														<span className="flex-2">RUN</span>
														<span className="flex-2">DEPOT</span>
														<span className="flex-1">DOCK</span>
														<span className="flex-2">TRAILER</span>
														<span className="flex-2">SCANNED</span>
														<span className="flex-3">LOADER</span>
													</div>
												</div>
													{
														unloadsCompleted.length === 0 ? (
															<Callout italicTitle title="NO UNLOADS COMPLETED" color="info" />
														) : (
															unloadsCompleted.map((unload, index) => <PalletUnloadBox small hideDuration key={index} data={unload} onClick={(id) => handleModalChanges(id, true) } />)
														)
													}
												</Col>
												<Col lg={6}>
													<div className="flex flex-column panel-row-container loads-panel-header small">
														<div className="flex-1 flex panel-row">
															<span className="flex-2">RUN</span>
															<span className="flex-2">DEPOT</span>
															<span className="flex-1">DOCK</span>
															<span className="flex-2">TRAILER</span>
															<span className="flex-2">SCANNED</span>
															<span className="flex-3">LOADER</span>
														</div>
													</div>
													{
														loadsCompleted.length === 0 ? (
															<Callout italicTitle title="NO LOADS COMPLETED" color="info" />
														) : (
															loadsCompleted.map((load, index) => <PalletLoadBox small hideDuration key={index} data={load} onClick={(id) => handleModalChanges(id, true) } />)
														)
													}
												</Col>
											</Row>
										</>
									)
								}
								{
									showModal && (
										<PalletLoadDetailsModal
											loadId={modalLoadId}
											onClose={() => setShowModal(false)}
											onCompleteSaving={() => handleModalChanges(modalLoadId, false)}
											onCompleteDeletion={() => handleModalChanges('', false)}
										/>
									)
								}
							</div>
						</div>
					)
				)
			}
		</section>
	)
}

export default PalletLoad;