import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import { IPalletLoadListItem, IPalletUnloadListItem } from '../../../../app/models/Pallet';

interface IProps {
	loads: IPalletLoadListItem[];
	unloads: IPalletUnloadListItem[];
}

const PalletLoadPanelSummary: React.FC<IProps> = props => {
	const { loads, unloads } = props;
	
	const renderBox = (title: string, color: string, icon: string, text: string | number) => (
		<Col sm={6} lg={3}>
			<div className="info-box">
				<span className={`info-box-icon bg-${color}`}>
					<Icon name={icon} />
				</span>
				<div className="info-box-content" style={{ position: 'relative' }}>
					<b style={{ fontSize: 55 }}>{ text }</b>
					<span style={{ fontSize: 22, position: 'absolute', left: 80, top: 28, fontWeight: 'bold' }}>{ title }</span>
				</div>
			</div>
		</Col>
	)

	const totalUnloadsInProgress = unloads.filter(p => !p.cancelledAt && !p.finishedAt).length;
	const totalUnloadsCompleted = unloads.filter(p => p.finishedAt).length;

	const totalLoadsInProgress = loads.filter(p => !p.loadCancelledOn && !p.loadFinishedOn).length;
	const totalLoadsCompleted = loads.filter(p => p.loadFinishedOn).length;	

	return (
		<Row>
			{ renderBox('UNLOADS IN PROGRESS', 'red', 'refresh', totalUnloadsInProgress) }
			{ renderBox('UNLOADS COMPLETED', 'red', 'check', totalUnloadsCompleted) }
			{ renderBox('LOADS IN PROGRESS', 'blue', 'refresh', totalLoadsInProgress) }
			{ renderBox('LOADS COMPLETED', 'blue', 'check', totalLoadsCompleted) }
		</Row>
	)
}

export default PalletLoadPanelSummary;